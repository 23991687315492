import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 612.000000 428.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
          <path d="M1006 4633 c-3 -4 -6 -489 -6 -1080 l0 -1073 380 0 380 0 0 510 c0
281 3 510 8 509 5 0 331 -417 493 -630 15 -20 20 -15 159 163 80 100 194 246
255 322 l110 139 3 -506 2 -507 380 0 380 0 -2 1078 -3 1077 -335 3 c-261 2
-339 0 -352 -10 -9 -7 -69 -80 -133 -163 -65 -82 -130 -166 -145 -185 -33 -41
-198 -253 -255 -327 -22 -29 -43 -52 -47 -52 -6 -1 -221 272 -434 550 -50 66
-104 135 -122 154 l-31 36 -340 -1 c-186 -1 -342 -4 -345 -7z"/>
<path d="M1080 2303 c64 -21 60 -4 60 -257 0 -133 5 -248 11 -271 35 -131 170
-205 351 -191 96 7 156 34 208 95 54 62 60 101 60 370 0 250 -4 233 60 254 8
3 -34 5 -95 5 -60 0 -100 -3 -88 -5 49 -9 53 -31 53 -269 0 -249 -8 -295 -62
-351 -54 -56 -163 -75 -233 -40 -40 19 -75 62 -95 114 -18 48 -20 78 -20 276
0 239 4 261 53 270 12 2 -45 5 -128 5 -82 0 -143 -2 -135 -5z"/>
<path d="M1937 2302 c13 -4 32 -15 43 -24 19 -17 20 -29 20 -326 0 -307 0
-309 -22 -329 -13 -12 -32 -23 -43 -26 -11 -3 77 -4 195 -3 224 3 261 8 320
49 66 45 89 102 76 191 -8 53 -68 121 -116 131 -31 7 -51 25 -28 25 17 1 50
29 73 63 20 31 24 122 6 156 -19 35 -74 69 -136 86 -43 11 -106 15 -235 14
-96 -1 -165 -4 -153 -7z m323 -61 c36 -26 50 -59 50 -118 0 -82 -28 -122 -99
-143 l-26 -8 24 -1 c42 -2 105 -39 126 -73 29 -49 27 -153 -4 -196 -30 -43
-70 -62 -131 -62 l-50 0 0 316 0 317 43 -7 c23 -4 53 -15 67 -25z"/>
<path d="M2640 2294 c19 -8 38 -20 42 -27 4 -7 8 -150 8 -318 0 -331 0 -332
-55 -348 -21 -6 20 -9 130 -9 104 0 150 3 132 8 -56 17 -56 19 -57 346 0 287
1 302 20 327 11 14 30 28 43 30 12 2 -50 5 -138 5 -151 1 -158 0 -125 -14z"/>
<path d="M2981 2298 c20 -5 50 -24 67 -41 37 -39 202 -295 202 -314 0 -7 -31
-57 -69 -111 -38 -53 -87 -121 -108 -150 -40 -56 -59 -71 -108 -83 -18 -4 24
-7 100 -8 111 -1 126 1 105 12 -14 7 -26 22 -28 34 -3 23 135 242 154 243 6 0
18 -15 27 -32 9 -18 40 -68 68 -111 57 -88 62 -126 19 -138 -14 -4 56 -7 155
-7 133 0 169 3 139 9 -23 5 -50 20 -61 32 -27 30 -223 334 -223 346 0 11 144
227 182 274 13 15 41 34 63 42 38 14 35 14 -85 14 -108 1 -122 -1 -100 -12 17
-9 25 -21 25 -38 0 -23 -112 -218 -126 -219 -3 0 -36 48 -72 106 -57 88 -65
108 -56 125 5 11 20 24 32 29 14 5 -45 9 -158 8 -135 0 -171 -3 -144 -10z"/>
<path d="M1533 1560 c-53 -17 -99 -68 -111 -125 -22 -104 6 -148 157 -247 124
-82 147 -101 155 -134 10 -39 -19 -93 -58 -109 -68 -29 -158 -13 -201 35 -10
11 -25 43 -35 72 l-17 53 -12 -43 c-7 -24 -11 -60 -9 -80 3 -33 8 -39 53 -62
42 -21 64 -24 140 -24 155 1 233 58 242 177 7 92 -11 117 -147 209 -141 95
-170 122 -170 156 1 109 200 126 244 21 13 -31 13 -30 19 32 4 34 6 63 5 65
-9 8 -229 12 -255 4z"/>
<path d="M1937 1543 c45 -9 48 -23 53 -269 l5 -232 30 -44 c89 -125 345 -146
455 -36 61 61 70 105 70 342 0 111 4 206 8 213 4 7 19 16 32 22 19 7 1 10 -75
9 -55 0 -89 -3 -75 -7 47 -13 51 -39 47 -261 -4 -229 -9 -254 -70 -307 -41
-36 -89 -48 -149 -39 -57 9 -96 41 -124 100 -23 49 -24 61 -24 261 0 116 4
215 8 222 4 7 19 16 32 22 18 7 -12 10 -110 9 -74 0 -125 -3 -113 -5z"/>
<path d="M2690 1454 c50 -21 50 -22 50 -269 0 -241 -1 -249 -45 -274 -29 -17
317 -8 365 10 43 15 81 44 97 72 17 30 16 102 -2 137 -17 32 -65 70 -89 70 -9
0 -16 5 -16 10 0 6 6 10 13 10 8 0 24 12 38 26 32 34 34 120 4 154 -43 48 -69
54 -255 57 -96 1 -168 0 -160 -3z m275 -59 c14 -13 25 -37 26 -52 3 -82 -14
-118 -63 -129 l-33 -7 35 -9 c46 -12 78 -43 90 -87 24 -86 -21 -159 -104 -168
l-46 -6 0 242 0 241 35 0 c25 0 44 -8 60 -25z"/>
<path d="M1594 649 c-4 -7 0 -15 8 -20 11 -6 14 -35 15 -134 2 -129 7 -154 24
-122 9 16 12 16 32 2 51 -36 112 -1 122 69 8 53 -8 94 -47 115 -24 14 -30 14
-62 -2 l-36 -17 0 60 c0 57 -1 60 -24 60 -14 0 -28 -5 -32 -11z m148 -126 c40
-36 14 -133 -35 -133 -55 0 -79 89 -35 130 26 24 45 25 70 3z"/>
<path d="M1854 646 c-8 -21 3 -36 27 -36 15 0 20 6 17 22 -4 27 -35 37 -44 14z"/>
<path d="M2650 646 c0 -9 4 -16 10 -16 5 0 11 -55 12 -132 2 -94 7 -133 16
-136 6 -2 12 3 12 12 0 20 7 20 27 0 18 -18 74 -14 99 7 22 18 33 74 24 116
-15 64 -69 91 -117 58 -22 -15 -22 -15 -25 42 -3 55 -4 58 -30 61 -20 2 -28
-1 -28 -12z m154 -128 c33 -46 7 -128 -40 -128 -38 0 -54 20 -54 65 0 73 59
113 94 63z"/>
<path d="M657 636 c-78 -29 -117 -88 -117 -177 0 -99 65 -165 173 -176 57 -6
118 6 124 24 3 8 -17 10 -70 6 -64 -5 -82 -3 -121 17 -57 27 -76 61 -76 133 0
96 64 157 165 157 98 0 161 -56 159 -141 -1 -55 -29 -97 -58 -86 -13 5 -16 23
-16 86 0 77 -12 110 -30 81 -6 -10 -15 -10 -42 0 -70 26 -125 -37 -111 -126
10 -63 77 -93 123 -56 23 18 24 18 42 0 35 -35 89 -12 114 48 45 107 -32 210
-164 220 -35 3 -72 -1 -95 -10z m98 -108 c21 -10 25 -18 25 -56 0 -71 -53
-108 -94 -66 -32 32 -10 134 29 134 8 0 26 -5 40 -12z"/>
<path d="M980 556 c0 -8 4 -16 9 -18 15 -5 18 -141 4 -145 -7 -3 -13 -11 -13
-19 0 -10 12 -14 40 -14 29 0 40 4 40 15 0 8 -4 15 -10 15 -5 0 -10 25 -10 55
0 43 5 58 22 75 44 41 68 18 68 -67 0 -35 -4 -63 -10 -63 -5 0 -10 -7 -10 -15
0 -11 11 -15 40 -15 29 0 40 4 40 15 0 8 -4 15 -10 15 -5 0 -10 25 -10 55 0
42 5 60 20 75 23 23 41 25 58 8 15 -15 16 -138 2 -138 -5 0 -10 -7 -10 -15 0
-11 11 -15 40 -15 28 0 40 4 40 14 0 8 -5 16 -12 18 -6 2 -13 33 -15 73 -4 83
-22 110 -67 101 -16 -4 -37 -13 -46 -21 -14 -13 -18 -12 -33 5 -22 26 -48 25
-81 -1 l-26 -20 0 20 c0 17 -6 21 -30 21 -19 0 -30 -5 -30 -14z"/>
<path d="M1350 556 c0 -9 4 -16 9 -16 4 0 11 -32 14 -71 7 -77 25 -109 62
-109 11 0 30 7 41 15 25 19 34 19 34 0 0 -9 9 -15 25 -15 16 0 25 6 25 15 0 8
-4 15 -10 15 -6 0 -10 37 -10 91 l0 90 -27 -3 c-28 -3 -45 -28 -19 -28 13 0
16 -11 16 -50 0 -41 -5 -56 -25 -75 -50 -50 -73 -27 -77 75 -3 74 -4 75 -30
78 -20 2 -28 -1 -28 -12z"/>
<path d="M1844 559 c-4 -7 1 -16 10 -21 12 -7 16 -24 16 -73 0 -49 -4 -66 -16
-73 -25 -14 -7 -32 32 -32 24 0 34 5 34 15 0 8 -4 15 -10 15 -6 0 -10 37 -10
90 0 89 0 90 -24 90 -14 0 -28 -5 -32 -11z"/>
<path d="M1960 555 c0 -8 6 -15 13 -15 6 0 25 -16 40 -35 l29 -36 -27 -35
c-15 -20 -33 -38 -41 -41 -8 -3 -14 -12 -14 -20 0 -10 10 -13 38 -11 33 3 37
6 34 29 -2 14 4 31 13 39 13 11 19 9 37 -10 15 -16 18 -25 10 -28 -7 -2 -12
-10 -12 -18 0 -10 13 -14 45 -14 33 0 45 4 45 15 0 8 -6 15 -12 15 -12 0 -68
62 -68 75 0 15 54 75 67 75 32 0 4 25 -32 28 -35 3 -37 2 -32 -20 3 -13 -1
-32 -9 -43 -15 -20 -15 -20 -34 5 -12 16 -15 29 -9 36 13 16 0 24 -43 24 -27
0 -38 -4 -38 -15z"/>
<path d="M2233 546 c-24 -21 -26 -27 -16 -49 8 -17 27 -30 63 -42 43 -14 51
-21 48 -38 -2 -17 -11 -23 -40 -25 -32 -3 -38 0 -38 16 0 10 -3 21 -6 25 -11
11 -34 -5 -34 -23 0 -61 141 -64 156 -3 8 31 -17 57 -71 75 -29 9 -40 19 -40
33 0 30 60 35 68 6 8 -31 37 -27 37 4 0 47 -81 61 -127 21z"/>
<path d="M2410 555 c0 -8 4 -15 9 -15 5 0 11 -34 13 -75 3 -60 7 -77 23 -89
26 -20 55 -20 82 -1 19 13 22 13 27 0 7 -20 56 -20 56 0 0 8 -4 15 -10 15 -5
0 -11 39 -12 88 -3 87 -3 87 -30 90 -20 2 -28 -1 -28 -12 0 -9 5 -16 10 -16 6
0 10 -25 10 -55 0 -62 -25 -99 -63 -93 -20 3 -22 10 -25 68 -3 96 -8 110 -37
110 -16 0 -25 -6 -25 -15z"/>
<path d="M3110 563 c-40 -15 -60 -46 -60 -96 0 -41 5 -53 30 -79 27 -27 36
-30 71 -25 36 5 68 28 69 50 0 13 -27 8 -40 -8 -13 -16 -48 -20 -66 -7 -39 26
-41 112 -3 133 33 17 69 3 69 -27 0 -19 5 -25 18 -22 28 5 26 43 -4 67 -26 20
-54 25 -84 14z"/>
<path d="M3324 561 c-34 -15 -54 -52 -54 -99 0 -35 6 -49 29 -73 90 -90 215
51 134 150 -20 24 -77 35 -109 22z m80 -40 c10 -11 18 -36 18 -56 0 -63 -51
-95 -98 -61 -27 20 -27 99 1 120 28 21 59 20 79 -3z"/>
<path d="M3506 561 c-3 -5 1 -14 9 -21 10 -9 15 -32 15 -75 0 -43 -5 -66 -15
-75 -21 -18 -9 -30 31 -30 24 0 34 5 34 15 0 8 -4 15 -10 15 -20 0 -11 96 11
124 26 33 60 31 70 -4 13 -48 10 -110 -6 -116 -32 -13 -16 -34 25 -34 29 0 40
4 40 15 0 8 -4 15 -10 15 -11 0 -13 71 -4 105 7 27 40 48 65 40 16 -6 19 -16
19 -76 0 -39 -4 -69 -10 -69 -5 0 -10 -7 -10 -15 0 -11 11 -15 40 -15 29 0 40
4 40 15 0 8 -4 15 -10 15 -6 0 -10 28 -10 64 0 49 -5 69 -21 90 -24 31 -43 32
-84 8 -28 -17 -30 -17 -47 0 -22 22 -61 23 -78 3 -7 -8 -16 -15 -21 -15 -5 0
-9 7 -9 15 0 16 -45 21 -54 6z"/>
<path d="M4020 556 c0 -8 4 -16 9 -18 15 -5 18 -141 4 -145 -7 -3 -13 -11 -13
-19 0 -10 12 -14 40 -14 29 0 40 4 40 15 0 8 -4 15 -10 15 -5 0 -10 25 -10 55
0 43 5 58 22 75 25 23 48 26 66 8 15 -15 16 -138 2 -138 -5 0 -10 -7 -10 -15
0 -11 11 -15 40 -15 28 0 40 4 40 14 0 8 -5 16 -12 18 -6 2 -13 32 -15 68 -3
36 -9 73 -14 82 -16 29 -57 33 -90 9 -29 -21 -29 -21 -29 -1 0 16 -7 20 -30
20 -19 0 -30 -5 -30 -14z"/>
<path d="M4325 558 c-34 -20 -44 -41 -45 -96 0 -43 5 -57 25 -77 27 -28 62
-32 91 -10 29 22 34 18 28 -17 -11 -58 -68 -79 -97 -35 -12 17 -18 19 -27 10
-18 -16 -5 -41 25 -53 42 -16 72 -12 102 14 27 23 28 28 31 135 3 61 8 111 13
111 5 0 9 7 9 15 0 9 -9 15 -25 15 -15 0 -25 -6 -25 -14 0 -13 -4 -13 -27 0
-33 17 -51 17 -78 2z m76 -28 c29 -16 27 -103 -3 -124 -32 -23 -55 -20 -74 9
-30 46 -2 125 44 125 7 0 22 -5 33 -10z"/>
<path d="M2941 410 c-19 -11 -9 -45 14 -45 13 0 21 8 23 21 3 23 -17 37 -37
24z"/>
<path d="M3911 410 c-19 -11 -9 -45 14 -45 13 0 21 8 23 21 3 23 -17 37 -37
24z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
